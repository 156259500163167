import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import CountUp from "react-countup"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css"
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "neon-coat-summer.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      appleButton: file(relativePath: { eq: "app-store.png" }) {
        childImageSharp {
          fluid(maxHeight: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      allThirdPartyStats {
        nodes {
          models
        }
      }
    }
  `)

   console.log("s",data)
    console.log("aa", data.placeholderImage.childImageSharp.fluid)
  return (
    <Layout title="Home">
      <SEO title="Influencer Marketing by Fashion Models at Scale" description="Neon Coat is a groundbreaking platform connecting quality brands with verified world-class models for organic marketing." image={data.placeholderImage.childImageSharp.fluid} />
      <div className="flex-grid home-grid">
        <div className="poster-left home-padder" />
        <div className="poster-text poster-left">
          <div className="vertical-center">
            Directly connecting brands with models for organic social marketing.
          </div>
        </div>
        <div style={{ flex: "1" }} className="poster-left" />
        <div className="home-hero-image">
          <Img fluid={data.placeholderImage.childImageSharp.fluid} alt="Neon Coat Home" />
        </div>
      </div>
      <div className="flex-grid">
        <div style={{ flex: "0 1 1000px" }}>
          <div className="flex-grid home-links" style={{ flexWrap: "wrap" }}>
            <div className="col home-box">
              <div className="home-subtitle">BRANDS</div>
              <div className="home-text">
                Neon Coat connects you to the world's most valuable influencer
                community—
                <b>
                  <CountUp
                    end={data.allThirdPartyStats.nodes[0].models}
                    separator=""
                    duration={1.5}
                  />
                </b>{" "}
                agency-signed models and counting. With the tap of a button,
                engage with these tastemakers and watch them introduce your
                brand to the world.
              </div>
              <div className="home-first-links">
                <Link to="brands">
                  <div className="home-button">CONNECT NOW</div>
                </Link>
                <Link to="brands#how-it-works">
                  <div className="home-button button-blue">LEARN MORE</div>
                </Link>
              </div>
            </div>
            <div className="col home-box">
              <div className="home-subtitle">MODELS</div>
              <div className="home-text">
                Discover your city with Neon Coat—complimentary gourmet meals,
                fitness classes, beauty treatments, cultural experiences and
                more. Connect with brands to receive gifts and become a brand
                ambassador. Make friends and give back through volunteer
                opportunities.
              </div>

              <div style={{ display: "flex" }}>
                <div className="apple-link">
                  <a href="/modelApp" target="_blank">
                    <Img
                      fluid={data.appleButton.childImageSharp.fluid}
                      alt="Neon Coat Home"
                    />
                  </a>
                </div>
                <div className="home-second-links">
                  <Link to="models">
                    <div className="home-button button-blue">LEARN MORE</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
